import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";

import WithdrawalModal from "./WithdrawalModal";
import Filter from "./Filter";
import PaginationComponent from "./Pagination";
import TableRow from "./TableRow";
import { applyFilters } from "./filterUtils";
import { sortByDate, sortByAmount, sortByStatus } from "./SortUtils";
import "./Table.css";

const Table = ({ data, showUsername, showWithdrawalType, year, session }) => {
  const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [availableWithdrawalTypes, setAvailableWithdrawalTypes] = useState([]);
  const [availableStatusStages, setAvailableStatusStages] = useState([]);
  const [availableStatusNames, setAvailableStatusNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "date", order: "desc" });

  const openModal = (withdrawal) => {
    setSelectedWithdrawal(withdrawal);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedWithdrawal(null);
    setModalIsOpen(false);
  };

  const handleSort = (key) => {
    let sortedData = [...filteredData];
    let newOrder = sortConfig.order === "asc" ? "desc" : "asc";

    switch (key) {
      case "date":
        sortedData = sortByDate(filteredData, newOrder);
        break;
      case "amount":
        sortedData = sortByAmount(filteredData, newOrder);
        break;
      case "status":
        sortedData = sortByStatus(filteredData, newOrder);
        break;
      default:
        break;
    }

    setFilteredData(sortedData);
    setSortConfig({ key, order: newOrder });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return "⇅";
    return sortConfig.order === "asc" ? "↑" : "↓";
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const sortedData = sortByDate(data, "desc");
      setFilteredData(sortedData);

      const types = [...new Set(data.map((item) => item.withdrawal_type))];
      const stages = [...new Set(data.map((item) => item.status.stage))];
      const names = [...new Set(data.map((item) => item.status.name))];
      setAvailableWithdrawalTypes(types);
      setAvailableStatusStages(stages);
      setAvailableStatusNames(names);
    }
  }, [data]);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  if (!filteredData || filteredData.length === 0) {
    return <div>ไม่มีข้อมูล</div>;
  }

  return (
    <div className="withdrawal-table">
      <Filter
        onFilter={(filters) => {
          const filtered = applyFilters(data, filters);
          setFilteredData(filtered.length > 0 ? filtered : data);
        }}
        availableWithdrawalTypes={availableWithdrawalTypes}
        availableStatusStages={availableStatusStages}
        availableStatusNames={availableStatusNames}
      />
      <br />
      <FormGroup className="d-flex align-items-center mb-3">
        <Label for="itemsPerPageSelect" className="me-2 mb-0">
          จำนวนรายการที่แสดง:
        </Label>
        <Input
          type="select"
          id="itemsPerPageSelect"
          onChange={handleItemsPerPageChange}
          value={itemsPerPage}
          style={{ width: "100px" }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </Input>
      </FormGroup>

      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort("date")}>
              วันเวลาที่เบิก {getSortIcon("date")}
            </th>
            <th>ชื่อรายการ</th>
            {showUsername && <th>ผู้เบิก</th>}
            {showWithdrawalType && <th>ประเภทการเบิก</th>}
            <th onClick={() => handleSort("amount")}>
              จำนวนเงินเบิก {getSortIcon("amount")}
            </th>
            <th onClick={() => handleSort("status")}>
              สถานะ {getSortIcon("status")}
            </th>
            <th>รายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <TableRow
              key={index}
              item={item}
              showUsername={showUsername}
              showWithdrawalType={showWithdrawalType}
              openModal={openModal}
            />
          ))}
        </tbody>
      </table>

      <PaginationComponent
        itemsPerPage={itemsPerPage}
        totalItems={filteredData.length}
        paginate={setCurrentPage}
        currentPage={currentPage}
      />

      {selectedWithdrawal && (
        <WithdrawalModal
          isOpen={modalIsOpen}
          toggle={closeModal}
          withdrawal={selectedWithdrawal}
          year={year}
          session={session}
        />
      )}
    </div>
  );
};

export default Table;
