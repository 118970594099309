import React from "react";
import { Pagination, PaginationItem, PaginationLink, Input } from "reactstrap";

const PaginationComponent = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleJump = (e) => {
    const pageNumber = parseInt(e.target.value, 10);
    if (pageNumber > 0 && pageNumber <= totalPages) paginate(pageNumber);
  };

  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <Pagination>
        {/* First Page */}
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink first onClick={() => paginate(1)} />
        </PaginationItem>

        {/* Previous Page */}
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
        </PaginationItem>

        {/* Page Numbers */}
        {pageNumbers
          .slice(
            Math.max(0, currentPage - 3),
            Math.min(totalPages, currentPage + 2)
          )
          .map((number) => (
            <PaginationItem key={number} active={currentPage === number}>
              <PaginationLink onClick={() => paginate(number)}>
                {number}
              </PaginationLink>
            </PaginationItem>
          ))}

        {/* Next Page */}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink next onClick={() => paginate(currentPage + 1)} />
        </PaginationItem>

        {/* Last Page */}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink last onClick={() => paginate(totalPages)} />
        </PaginationItem>
      </Pagination>

      {/* Page Info */}
      <div>
        <span>
          หน้าที่ {currentPage} จากทั้งหมด {totalPages}
        </span>
      </div>

      {/* Jump to Page */}
      <div>
        <span>ไปยังหน้า: </span>
        <Input
          type="number"
          min="1"
          max={totalPages}
          placeholder="เลขหน้า"
          onBlur={handleJump}
          style={{ width: "100px", display: "inline-block" }}
        />
      </div>
    </div>
  );
};

export default PaginationComponent;
